//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
         <h1><i>kd51nc</i>
             <br />
             <br />
         coming soon!</h1>
    </div>
  );
}

export default App;
